import React, { Component } from 'react';
import ProgressBar from 'progressbar.js';


var bar;
var intervalim;

class gostergeS extends Component{
    constructor(props){
        super(props);
        this.state={
            araba:null,
            hiz:0
        }
        this.barYap=this.barYap.bind(this);
        //console.log(props.araba);
    }
    componentDidMount(){
        this.setState({araba:this.props.araba},this.barYap);
    }
    barYap(){
        console.log('çalıştı')
        var hizim;
        if(this.state.araba){
            hizim=parseFloat(String(this.state.araba.bilgiler.SPEEDKMH).split(',').join('.'));
        }else{
            hizim=parseFloat('0');
        }

        bar = new ProgressBar.SemiCircle('#container', {
            strokeWidth: 3,
            color: '#FFEA82',
            trailColor: '#1B1C1D',
            trailWidth: 1,
            easing: 'easeInOut',
            duration: 1400,
            svgStyle: null,
            text: {
              value: '',
              alignToBottom: false
            },
            from: {color: '#FF0000'},
            to: {color: '#ED6A5A'},
            // Set default step function for all animate calls
            step: (state, bar) => {
              bar.path.setAttribute('stroke', state.color);
              var value = Math.round(bar.value() * 240);
              if (value === 0) {
                bar.setText('');
              } else {
                bar.setText(value+'km/sa');
              }
              bar.text.style.color = state.color;
            }
        });
        bar.text.style.fontFamily = '"Raleway", Helvetica, sans-serif';
        bar.text.style.fontSize = '2rem';

        
        bar.animate(hizim/240);
        if(hizim>5){
            intervalim=setInterval(function(){ bar.animate((hizim+(Math.round(Math.random()) * 2 - 1))/240)}, 1000);
        }
        
    }

    componentWillReceiveProps(nextProps){
        clearInterval(intervalim);
        var hizim;
        if(nextProps.araba){
            hizim= parseFloat(String(nextProps.araba.bilgiler.SPEEDKMH).split(',').join('.'))
        }else{
            hizim= parseFloat('0')
        }
        bar.animate(hizim/240); 
        this.setState({hiz:hizim});
        if(hizim>5){
            intervalim=setInterval(function(){ bar.animate((hizim+(Math.round(Math.random()) * 2 - 1))/240)}, 1000);
        }
        
    }

    render(){
        return(
            <div id="container"></div>);
        }
}


export default gostergeS;