
var React = require('react');
var { Table } =require ('antd');


class Tablo extends React.Component{
    constructor(props){
        super(props);
        this.state={
            loading:true,
            datam:[{
                adress:'',
                course:'',
                createdAdt:'',
                fixdatetime:'',
                fixlength:'',
                ignition:'',
                lat:'',
                lng:'',
                durum:'yok',
                speed:'5'}]
        }
    }
    componentDidMount(){
        this.setState({data:this.props.data,loading:false});
    }
    componentWillReceiveProps(newProps){
        if(typeof newProps.data !== 'undefined'){
            if(newProps.data.length>0){
                this.setState({data:newProps.data,loading:false});
            }
        }
        
    }

    render(){
        var child=this.props.children
        const columns = [{
            title: 'Zaman',
            dataIndex: 'fixdatetime',
            width: '20%'
          }, {
            title: 'Durum',
            dataIndex: 'durum',
            width: '10%'
          }, {
            title: 'Hız',
            dataIndex: 'speed',
            width: '10%'
          }, {
            title: 'Adres',
            dataIndex: 'adress',
            width: '60%'
          }];
        const {data,loading} = this.state;
        return(
           
            <Table size="small" bordered pagination columns={columns} loading={loading} dataSource={data} footer={() => child} />
            
        )
    }
}

export default Tablo;



