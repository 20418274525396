import {Input,Step,Dimmer,Loader,Sidebar,Checkbox,Segment,Menu,Icon,Grid,Image,Label,Popup,Dropdown,Modal} from 'semantic-ui-react';

import 'es6-shim';
import _ from 'lodash';

var React= require('react');

var keybas=0;
function keyYap(){
    keybas+=1;
    return keybas

}

var menuOBJ={};
class Sidebarim extends React.Component {
    

    constructor(props){
        super(props);
        this.state={
            girisObj:props.girisObj,
            sTI:'',
            itsarray:[],
            visible: false,
            animation: 'fly right',
            raporDimmer: false,
            duration: 600,
            topen: false,
            modalIcerik: '',
            modalBaslik: '',
            modalOpen:false,
            active:'',
            gosterim:{tumAraclar:[''],
                hareketHalindekiAraclar:[''],
                duranAraclar:[''],
                rolantidekiAraclar:[''],
                alarmHalindekiAraclar:[''],
                bilgiAlinamayanAraclar:[''],
                gpsSinyaliAlinamayanAraclar:['']},
            tcbc:{
                tumAraclar:true,
                hareketHalindekiAraclar:true,
                duranAraclar:true,
                rolantidekiAraclar:true,
                alarmHalindekiAraclar:true,
                bilgiAlinamayanAraclar:true,
                gpsSinyaliAlinamayanAraclar:true
            },
            plakaDropdown:[],
            activeStep:'aramaVis',
            s2d:true,
            s3d:true,
            plakaAc:true,
            animasyonlar:true,
            zoomHide:false,
            trafik:false,
            filteredRes:[]
        }
        this.buttonOnClick=this.buttonOnClick.bind(this);
        this.activeUpdate=this.activeUpdate.bind(this);
        this.inactiveUpdate=this.inactiveUpdate.bind(this);
        this.stepHandleClick=this.stepHandleClick.bind(this);
        this.tcboc=this.tcboc.bind(this);
        this.handleClick= this.handleClick.bind(this);
        this.toggleVisibility= this.toggleVisibility.bind(this);
        this.handleItemClick= this.handleItemClick.bind(this);
        this.renderTrafik=this.renderTrafik.bind(this);
        this.renderAnimasyonlar=this.renderAnimasyonlar.bind(this);
        this.renderPlakaAc=this.renderPlakaAc.bind(this);
        this.renderZoomHide=this.renderZoomHide.bind(this);
        this.labelClick=this.labelClick.bind(this);
        this.sTHandler=this.sTHandler.bind(this);
        this.renderFilterableMenu=this.renderFilterableMenu.bind(this);
        this.renderFullScreenButton=this.renderFullScreenButton.bind(this);
        
    }
    
    renderFullScreenButton(){
        if(this.state.fullScreen){
            return (<Menu.Item className='yanMenuOge' onClick={this.props.toFullScreen}>
                        <Icon name='minimize'/>Tam Ekran'dan Çık
                    </Menu.Item>);
        }else{
            return (<Menu.Item className='yanMenuOge' onClick={this.props.toFullScreen}>
                        <Icon name='maximize'/>Tam Ekran
                    </Menu.Item>);
        }
    }
    sTHandler(event,data){
        

        var donen=menuOBJ[this.state.active].filter(function(obj){
            var donen;
            if(obj.plaka.includes(data.value.toUpperCase())){
                donen= obj
            }
            return donen
        });

        this.setState({sTI:data.value, filteredRes:donen});

        //////////////////////////////burda kaldım
    }

    renderFilterableMenu(){
        var activated=this.state.active;
        var active;
        var sTI=this.state.sTI;
        var labelClick=this.labelClick;
        var cboc = this.props.cboc;
        if(activated){
            if(sTI.length>0){
                active=this.state.filteredRes;
                return active.map(function(key, index) {
                    var imei= key.imei;
                    return(
                        <Menu.Item className='yanMenuOge' as={Segment} name={'I'+imei} key={'y'+activated+imei+keyYap()}>
                        <Grid columns={2}>
                        <Grid.Column width={2}>
                            <Checkbox name={imei} checked={active[index].gosterim} onChange={cboc}/>
                        </Grid.Column>
                        <Grid.Column className='notmarker' name={imei} onClick={labelClick} width={12}>
                            {active[index].plaka}
                        </Grid.Column>
                        </Grid>
                        </Menu.Item>
                    )
                });
            }else{
                active=this.state.active;
                return menuOBJ[active].map(function(key, index) {
                    var imei= key.imei;
                    return(
                        <Menu.Item className='yanMenuOge' as={Segment} name={'I'+imei} key={'y'+active+imei+keyYap()}>
                            <Grid columns={2}>
                            <Grid.Column width={2}>
                                <Checkbox name={imei} checked={menuOBJ[active][index].gosterim} onChange={cboc}/>
                            </Grid.Column>
                            <Grid.Column className='notmarker' name={imei} onClick={labelClick} width={12}>
                                {menuOBJ[active][index].plaka}
                            </Grid.Column>
                            </Grid>
                        </Menu.Item>
                    )
                });
            }
        }
    }

    buttonOnClick(event,data){
        //var datam=data.itsarray[0];
        var datam=data;
        var tempArr=[];
        var keyler=0;
        _.forEach(datam, function(value) {
            var altOBJ={};
            altOBJ.adress=value.adress;
            altOBJ.course=value.course;
            altOBJ.createdAdt=value.createdAdt;
            altOBJ.fixlength=value.fixlength;
            altOBJ.ignition=value.ignition;
            altOBJ.lat=value.lat;
            altOBJ.lng=value.lng;

            altOBJ.key=keyler;
            altOBJ.speed=Number(value.speed).toFixed(2).toString();
            altOBJ.fixdatetime=value.fixdatetime;

            if(Number(value.speed)>5){
                altOBJ.durum='Hareket Halinde';
            }else{
                altOBJ.durum='Duruyor';
            }
            tempArr.push(altOBJ);
            keyler+=1;
        });
        this.setState({itsarray:tempArr,s2d:false,raporDimmer:false});
        this.props.setActiveStep('listeVis');
    }
    toggleVisibility(){
        this.setState({ visible: !this.state.visible });
        if(this.state.topen===true){
            this.handleClick();
        }
    }
    handleClick(){
        if( typeof arguments[2]!=='undefined'){
            this.setState({sTI:'', topen: !this.state.topen, active:arguments[2].name });
        }else{
            this.setState({sTI:'', topen: !this.state.topen, active:'' });
        }
        if(this.state.visible===true){
            this.toggleVisibility();
        }
    }
    handleItemClick(e, { name }){
        this.setState({ activeItem: name });
    }
    modalKapatHandler(){
        this.setState({modalOpen:false});
    }
    stepHandleClick(e, { name }){
        if(name==='aramaVis'){
            this.setState({activeStep:name,s2d:true});
        }else{
            this.setState({activeStep:name});
        }
    }
    tcboc(event,data){
        var temptcbc= this.state.tcbc;
        temptcbc[this.state.active]=!temptcbc[this.state.active];
        this.setState({tcbc : temptcbc});
        this.props.tcboc(this.state.active,data.checked);
    }
    componentDidMount(){
        this.setState({activeStep:this.props.activeStep});
    }
    componentWillReceiveProps(newProps){
        var tempArr=[];
        _.forEach(newProps.aracVeri, function(value, key) {
            tempArr.push({'text': value.plaka , 'value':value.imei});
        });
        this.setState({girisObj:newProps.girisObj, activeStep:newProps.activeStep, gosterim:newProps.gosterim, plakaDropdown:tempArr,plakaAc:newProps.plakaAc,animasyonlar:newProps.animasyonlar,zoomHide:newProps.zoomHide,trafik:newProps.trafik});
    }

    activeUpdate(){
        this.setState({raporDimmer:true});
    }
    inactiveUpdate(){
        this.setState({raporDimmer:false});
    }
    renderTrafik(){
        if(this.state.trafik===true){
            return(<div><Icon name='checkmark'/>Trafik Katmanı</div>)
        }else{
            return(<div><Icon name='close'/>Trafik Katmanı</div>)
        }
    }
    renderAnimasyonlar(){
        if(this.state.animasyonlar===true){
            return(<div><Icon name='checkmark'/>Animasyonlar</div>)
        }else{
            return(<div><Icon name='close'/>Animasyonlar</div>)
        }
    }
    renderPlakaAc(){
        if(this.state.plakaAc===true){
            return(<div><Icon name='checkmark'/>Plakaları Göster</div>)
        }else{
            return(<div><Icon name='close'/>Plakaları Göster</div>)
        }
    }
    renderZoomHide(){
        if(this.state.zoomHide===true){
            return(<div><Icon name='checkmark'/>Zoom'da Performans</div>)
        }else{
            return(<div><Icon name='close'/>Zoom'da Performans</div>)
        }
    }
    labelClick(e,data){
        var tar= e.target;
        var imei=tar.getAttribute('name');
        this.setState({topen:false},this.props.imeiTransfer(imei));
        //;
    }
    signoutClick=()=>{
        window.location.href = 'http://serodasan.5xc.org/';
    }
    render(){
        const aracVeri = this.props.aracVeri;
        const trafficHandler = this.props.trafficHandler;
        var raporHandler=this.props.raporHandler


        
        menuOBJ.tumAraclar=_.filter(aracVeri,{ tumAraclar:true });
        menuOBJ.hareketHalindekiAraclar=_.filter(aracVeri,{ hareketHalindekiAraclar:true });
        menuOBJ.duranAraclar=_.filter(aracVeri,{ duranAraclar:true });
        menuOBJ.rolantidekiAraclar=_.filter(aracVeri,{ rolantidekiAraclar:true });
        menuOBJ.alarmHalindekiAraclar=_.filter(aracVeri,{ alarmHalindekiAraclar:true });
        menuOBJ.bilgiAlinamayanAraclar=_.filter(aracVeri,{ bilgiAlinamayanAraclar:true });
        menuOBJ.gpsSinyaliAlinamayanAraclar=_.filter(aracVeri,{ gpsSinyaliAlinamayanAraclar:true });

        const {sTI, itsarray ,raporDimmer,activeStep, plakaDropdown, tcbc, active, topen, activeItem, s2d, s3d } = this.state
        return(
            <div>
            <Sidebar.Pushable as={Segment}>
            <Sidebar as={Menu} animation='slide out' width='thin' visible={this.state.visible} icon='labeled' vertical inverted>
                <Menu.Item className='yanMenuOge' onClick={this.handleClick.bind(this,this)} name='tumAraclar'>
                    <Icon name='shipping' />
                    Tüm Araçlar ({menuOBJ.tumAraclar.length})
                </Menu.Item>
                <Menu.Item className='yanMenuOge' onClick={this.handleClick.bind(this,this)} name='hareketHalindekiAraclar'>
                    <Icon name='dashboard' />
                    Hareketli Araçlar ({menuOBJ.hareketHalindekiAraclar.length})
                </Menu.Item>
                <Menu.Item className='yanMenuOge' onClick={this.handleClick.bind(this,this)} name='duranAraclar'>
                    <Icon name='stop circle' />
                    Duran Araçlar ({menuOBJ.duranAraclar.length})
                </Menu.Item>
                <Menu.Item className='yanMenuOge' onClick={this.handleClick.bind(this,this)} name='rolantidekiAraclar'>
                    <Icon name='refresh' />
                    Rolantideki Araçlar ({menuOBJ.rolantidekiAraclar.length})
                </Menu.Item>
                <Menu.Item className='yanMenuOge' onClick={this.handleClick.bind(this,this)} name='alarmHalindekiAraclar'>
                    <Icon name='warning circle' />
                    Alarm Halindeki Araçlar ({menuOBJ.alarmHalindekiAraclar.length})
                </Menu.Item>
                <Menu.Item className='yanMenuOge' onClick={this.handleClick.bind(this,this)} name='bilgiAlinamayanAraclar'>
                    <Icon name='help circle' />
                    Bilgi Alınamayan Araçlar ({menuOBJ.bilgiAlinamayanAraclar.length})
                </Menu.Item>
                <Menu.Item className='yanMenuOge' onClick={this.handleClick.bind(this,this)} name='gpsSinyaliAlinamayanAraclar'>
                    <Icon name='compass' />
                    GPS Sinyali Olmayan Araçlar ({menuOBJ.gpsSinyaliAlinamayanAraclar.length})
                </Menu.Item>
            </Sidebar>
            <Sidebar.Pusher style={{height: window.innerHeight-3}}>
            <div>
            
            <Menu color='black' inverted stackable>
                <Menu.Item className='ustMenuOge' onClick={this.toggleVisibility.bind(this)}>
                <Icon name='content'></Icon>
                </Menu.Item>
                <Menu.Item as='a' href='http://www.serodasan.com' className='logoItem'>
                <Image fluid src='./img/logo.png' />
                </Menu.Item>
                <Menu.Item as={Dropdown} item className='ustMenuOge' name='raporlar' active={activeItem === 'raporlar'} text='Raporlar' onClick={this.handleItemClick.bind(this)}>
                    <Dropdown.Menu>
                        <Dropdown.Item name='rapor' onClick={raporHandler}>Hareket Raporu</Dropdown.Item>
                    </Dropdown.Menu>
                </Menu.Item>
                <Menu.Item as={Dropdown} item className='ustMenuOge' name='ayarlar' active={activeItem === 'ayarlar'} text='Ayarlar' onClick={this.handleItemClick.bind(this)}>
                    <Dropdown.Menu>
                        <Dropdown.Item name='trafik' onClick={trafficHandler}>
                        {this.renderTrafik()}
                        </Dropdown.Item>
                        <Dropdown.Item name='plakaAc' onClick={trafficHandler}>
                        {this.renderPlakaAc()}
                        </Dropdown.Item>
                        <Dropdown.Item name='animasyonlar' onClick={trafficHandler}>
                        {this.renderAnimasyonlar()}
                        </Dropdown.Item>
                        <Dropdown.Item name='zoomHide' onClick={trafficHandler}>
                        {this.renderZoomHide()}
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Menu.Item>

                <Menu.Menu position='right'>
                {/*this.renderFullScreenButton()*/}
                <Popup flowing hoverable trigger={
                    <Menu.Item name='uyarilar'>
                        Bildirimler <Label color='teal'>0</Label>
                    </Menu.Item>}
                content='Okunmamış bildiriminiz yok!'
                />
                <Menu.Item onClick={this.signoutClick} className='yanMenuOge'>
                    <Icon name='sign out'/>Çıkış Yap
                </Menu.Item>
                </Menu.Menu>
            </Menu>
            </div>
            <div className='icHarita'>
                <Sidebar.Pushable as={Segment}>
                
                <Sidebar as={Menu} animation='slide out' width='thin' visible={topen} icon='labeled' vertical>
                <Menu.Item className='yanMenuOge' onClick={this.toggleVisibility} as={Segment} name='deneme'>
                <div><Icon name='arrow left'></Icon> Geri</div>
                </Menu.Item>
                    {
                        (active!=='')&&
                        <Input placeholder='Plaka Giriniz' style={{width: 120 + 'px'}} value={sTI} onChange={this.sTHandler}/>
                    }
                    {
                        (active!=='')&&
                        <Menu.Item  className='yanMenuOge' as={Segment} key={'y'+active+keyYap()}>
                        <Checkbox label='Tümünü Aç/Kapat' checked={tcbc[active]} onChange={this.tcboc}/>
                        </Menu.Item>
                    }

                    {
                        this.renderFilterableMenu()
                    }
                <Menu.Item className='yanMenuOge' onClick={this.toggleVisibility} as={Segment} name='deneme'>
                <div><Icon name='arrow left'></Icon> Geri</div>
                </Menu.Item>
                </Sidebar>
                    <Sidebar.Pusher>
                        <div>{this.props.children}</div>
                       
                    </Sidebar.Pusher>
                </Sidebar.Pushable>

            </div>
            </Sidebar.Pusher>
            </Sidebar.Pushable>
            </div>
            
        )
    }
}

export default Sidebarim;