import {Modal, Dimmer, Loader, Button,  Grid, Form, Label} from 'semantic-ui-react';
import axios from 'axios';
var React = require('react');

var girisObj={};

class Login extends React.Component{
    constructor(props){
        super(props);
        this.state={
          open:true,
          active:false,
          visible:true
        }
    }

    gonder=()=>{
        this.setState({ active: true});
        this.handleLoginPass(girisObj);
    }

    girisHata=()=>{
        this.setState({visible: false, active:false});
    }
    
    handleLoginPass(gelen){
        var onLoginPass=this.props.onLoginPass;
        axios.post('http://107.180.69.212:7294/usr/login', gelen).then((response)=>{
            console.log(response.data.tumBilgiler, Object.keys(response.data.tumBilgiler));
            if(response.data.token!==0){
                onLoginPass(response.data,girisObj);
            }else{
                this.girisHata();
            }
        }).catch((err)=>{
            console.log(err);
        })
    }

    inHC=(e,{value})=>{
        girisObj[e.target.name]=value;
        //console.log(girisObj)
    }

    render(){
        const { visible,active } = this.state;
        return(
                <Modal size='mini' open={this.state.open} closeOnDimmerClick={false}>
                    <Modal.Header className='modalTuruncuHeader'>
                    Giriş Yapınız
                    </Modal.Header>
                    <Modal.Content>
                        <Dimmer active={active}>
                            <Loader>Hesabınıza Ait Bilgiler Alınıyor...</Loader>
                        </Dimmer>
                        {(!visible)&&<Grid centered><Grid.Row centered><Label basic color='orange'>Hatalı şifre veya kullanıcı adı!</Label></Grid.Row></Grid>}
                        <Form onSubmit={this.loginIdAl}>
                            <Form.Field>
                                <label>Kullanıcı Adı</label>
                                <Form.Input placeholder='Kullanıcı Adı' name='username' onChange={this.inHC}/>
                            </Form.Field>
                            <Form.Field>
                                <label>Şifre</label>
                                <Form.Input type="password" placeholder='Şifre' name='password' onChange={this.inHC}/>
                            </Form.Field>
                            <Button className='butonlar' type='submit' icon='checkmark' labelPosition='right' content='Giriş' onClick={this.gonder}></Button>
                        </Form>
                    </Modal.Content>
                </Modal>
        )
    }





}

export default Login;