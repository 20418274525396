import React, { Component } from 'react';
import {Sidebar, Segment, Button, Icon, Label, List,Form } from 'semantic-ui-react';
import { Slider, Alert } from 'antd';
import _ from 'lodash';

import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

import SimuS from './simuS';




function getSum(simulasyonData, currFrame) {
    var tempArr=simulasyonData.slice(0,currFrame);
    var mesafe=Number(_.sumBy(tempArr, function(o) { return o.fixlength; }));
    if(mesafe<1000){
        return mesafe+' m';
    }else{
        return (mesafe/1000).toFixed(2) + ' km';
    }
}

function getSM(simulasyonData, currFrame){
    var tempArr=simulasyonData.slice(0,currFrame);
    var mesafe=Number(_.sumBy(tempArr, function(o) { return o.fixlength; }));

    if(mesafe<15000){
        return '105';

        if(simulasyonData.length-1===currFrame){
            console.log('Simülasyon bitti, Toplam tutar: 105 ₺')
            alert('Simülasyon bitti, Toplam tutar: 105 ₺');
           /* toast.error('Simülasyon bitti, Toplam tutar: 105 ₺', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
                });*/
        }
    }else if(mesafe>=15001){
        var donecek=(Number(((mesafe-15000)/1000).toFixed(2)*3)+105).toFixed(2);

        //if(simulasyonData.length-1===currFrame){
            //console.log('Simülasyon bitti, Toplam tutar: '+donecek+' ₺')
            //alert('Simülasyon bitti, Toplam tutar: '+donecek+' ₺');
            /*toast.error('Simülasyon bitti, Toplam tutar: '+donecek+' ₺', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
                });*/
        //}
        /*console.log(mesafe)
        console.log(mesafe-15000);
        console.log((mesafe-15000)/1000);
        console.log(((mesafe-15000)/1000).toFixed(2));*/

        return donecek;
    }else{
        return ' ';
    }
    /*
    

        /*(Number(Number(oge[1])/1000).toFixed(2)>=15) 
             ( {(Number((Number(oge[1])/1000).toFixed(2)*3)+60).toFixed(2)}₺)*/
        /*(Number(Number(oge[1])/1000).toFixed(2)<5) 0₺)

        /*((Number(Number(oge[1])/1000).toFixed(2)<15)
         105₺</Label>)*/
}

class sayrintiS extends Component{
    constructor(props){
        super(props);
        this.state={
            visible:true,
            markerInfo:null,
            simulasyonData:null,
            currFrame:0,
            play:false,
            ileriDisabled:false,
            geriDisabled:true,
            pokeFnc:null
        }
        this.playHandler=this.playHandler.bind(this);
        
        this.playRender=this.playRender.bind(this);
        this.basaDonHandler=this.basaDonHandler.bind(this);
        this.geriHandler=this.geriHandler.bind(this);
        this.ileriHandler=this.ileriHandler.bind(this);
        this.sonaGitHandler=this.sonaGitHandler.bind(this);
        this.setCurrentFrame=this.setCurrentFrame.bind(this);
        this.geriRender=this.geriRender.bind(this);
        this.ileriRender=this.ileriRender.bind(this);
        this.sliderHandler=this.sliderHandler.bind(this);
    }
    setCurrentFrame(gelen=null){
        if(gelen){
            
            this.setState({currFrame:gelen, play:false});
        }else{
            if(this.state.currFrame!==this.state.simulasyonData.length-1){
                this.setState({currFrame:this.state.currFrame+1});
            }else{
                this.setState({play:false});
            }
        }
    }
    menuKapa(){
        //console.log('Simülasyonu kapa');
    }
    componentDidMount(){
        this.setState({simulasyonData:this.props.simulasyonData,pokeFnc:this.props.pokeFnc});
    }
    componentWillReceiveProps(newProps){
        this.setState({simulasyonData:newProps.simulasyonData,pokeFnc:newProps.pokeFnc});
    }
    playHandler(){
        this.setState({play:!this.state.play});
    }
    basaDonHandler(){
        this.setState({play:false,currFrame:0,geriDisabled:true,ileriDisabled:false});
    }
    geriHandler(){
        this.setState({play:false,currFrame:this.state.currFrame-1});
    }
    ileriHandler(){
        this.setState({play:false,currFrame:this.state.currFrame+1});
    }
    sonaGitHandler(){
        this.setState({play:false,currFrame:this.state.simulasyonData.length-1,ileriDisabled:true, geriDisabled:false});
    }
    sliderHandler(value){
        this.setState({play:false,currFrame:value});
    }

    geriRender(){
        if(this.state.simulasyonData){
            if(this.state.currFrame===0){
                return (<Button disabled={true} onClick={this.geriHandler}>
                            <Icon  name='backward' />
                        </Button>)
            }else{
                return (<Button disabled={false} onClick={this.geriHandler}>
                            <Icon  name='backward' />
                        </Button>)
            }
        }else{
            return (<div></div>)
        }
        
    }
    playRender(){
        if(!this.state.play){
            return (<Button onClick={this.playHandler}> <Icon name='play' /> </Button>)
        }else{
            return (<Button onClick={this.playHandler}> <Icon name='pause' /> </Button>)
        }
    }

    ileriRender(){
        if(this.state.simulasyonData){
            if(this.state.currFrame===this.state.simulasyonData.length-1){
                return (<Button disabled={true}  onClick={this.ileriHandler}>
                            <Icon name='forward' />
                        </Button>)
            }else{
                return (<Button disabled={false}  onClick={this.ileriHandler}>
                            <Icon name='forward' />
                        </Button>)
            }
        }else{
            return (<div></div>)
        }
        
    }

    render(){
        const {visible}=this.state;
        var divStyle={
            'paddingTop': '25px',
            'width':'200px',
            'textAlign':'left'
        };

        var innerStyle={
            'textAlign':'left'
        }

        var sliderStyle={
            color:'red'
        }
        
        return(
        <div>
            <Sidebar.Pushable as={Segment}>
                <Sidebar className='bilgiSide'
                    as={List}
                    animation='overlay'
                    direction='right'
                    visible={visible}
                    icon='labeled'
                    align='center'
                    inverted>
                    <div style={divStyle}>
                        <Form style={innerStyle}>
                            <Form.Field>
                                {(this.state.simulasyonData)&&<Slider style={sliderStyle} min={0} max={this.state.simulasyonData.length-1} onChange={this.sliderHandler} value={this.state.currFrame} />}
                            </Form.Field>
                            <Form.Field>
                                <Button.Group color='red' size='small' icon>
                                    <Button onClick={this.basaDonHandler}>
                                        <Icon  name='step backward' />
                                    </Button>
                                    {
                                        this.geriRender()
                                    }
                                    {
                                        this.playRender()
                                    }
                                    {
                                        this.ileriRender()
                                    }
                                    
                                    <Button onClick={this.sonaGitHandler}>
                                        <Icon  name='step forward' />
                                    </Button>
                                </Button.Group>
                            </Form.Field>
                            
                            <Form.Field>
                                <Label color='red' image>Tarih: <Label.Detail>{(this.state.simulasyonData)&&this.state.simulasyonData[this.state.currFrame].fixdatetime}</Label.Detail> </Label>
                            </Form.Field>
                            <Form.Field>
                                <Label color='red' image>Toplam Mesafe: <Label.Detail>{(this.state.simulasyonData)&&getSum(this.state.simulasyonData,this.state.currFrame)}</Label.Detail> </Label>
                            </Form.Field>
                            <Form.Field>
                                <Label color='red' image>Mesafe: <Label.Detail>{(this.state.simulasyonData)&&this.state.simulasyonData[this.state.currFrame].fixlength} metre</Label.Detail> </Label>
                            </Form.Field>
                            {/**
                            <Form.Field>
                                <Label color='red' image>ServisMetre: <Label.Detail>{(this.state.simulasyonData)&&getSM(this.state.simulasyonData,this.state.currFrame)} ₺</Label.Detail> </Label>
                            </Form.Field>
                             */}
                            
                            <Form.Field>
                                <Label color='red' image>Hız: <Label.Detail>{(this.state.simulasyonData)&&this.state.simulasyonData[this.state.currFrame].speed} km/s</Label.Detail> </Label>
                            </Form.Field>
                            <Form.Field>
                                <Label color='red' image>Durum: <Label.Detail>{(this.state.simulasyonData)&&this.state.simulasyonData[this.state.currFrame].durum}</Label.Detail> </Label>
                            </Form.Field>
                            <Form.Field>
                                <Label color='red' >Adres: {(this.state.simulasyonData)&&this.state.simulasyonData[this.state.currFrame].adress}</Label>
                            </Form.Field>
                        </Form>
                    </div>
                </Sidebar>
                <Sidebar.Pusher>
                    {
                        window.google&&
                        <SimuS
                            pokeFnc={this.state.pokeFnc} 
                            setCurrentFrame={this.setCurrentFrame} 
                            play={this.state.play} 
                            currFrame={this.state.currFrame} 
                            simulasyonData={this.state.simulasyonData}>
                        </SimuS>
                    }
                   
                </Sidebar.Pusher>
        </Sidebar.Pushable>
        <ToastContainer position="bottom-right"
                    autoClose={5000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnVisibilityChange
                    draggable
                    pauseOnHover/>
        </div>)
    }
}

export default sayrintiS;