import React, { Component } from 'react';
import {Sidebar, Segment, Button, Icon, Label, List, Tab } from 'semantic-ui-react';
import GostergeS from './gostergeS';
import moment from 'moment';
moment.locale('tr');


function renderChildren(thisim,_seciliImei) {
    return React.Children.map(thisim.props.children, child => {
        return React.cloneElement(child, {
            seciliImei:_seciliImei
        })
    })
  }

class gostergeS extends Component{
    constructor(props){
        super(props);
        this.state={
            visible:false,
            araba:null,
            veriler:props.veriler,
            seciliImei:null
        }
        this.menuKapa=this.menuKapa.bind(this);
        
    }
    
    menuKapa(){
        this.props.imeiSil();
    }
    componentWillReceiveProps(newProps){
        var _visible;
        var _araba;
        if(newProps.solMenuImei){
             _visible=true;
            _araba=newProps.veriler[newProps.solMenuImei];
            this.setState({araba:_araba, veriler:newProps.veriler, seciliImei:newProps.solMenuImei, visible:_visible});

        }else{
            _visible=false;
            this.setState({ veriler:newProps.veriler, seciliImei:newProps.solMenuImei, visible:_visible});

        }

    }
    formatDate(date) {
        if(date){
            
            return moment(date).subtract(3,'hours').format('LLL');
            /*console.log(date);
            var gT=new Date(date);
        
            var monthNames = [
            "Ocak", "Şubat", "Mart",
            "Nisan", "Mayıs", "Haziran", "Temmuz",
            "Ağustos", "Eylül", "Ekim",
            "Kasım", "Aralık"
            ];
            var day = gT.getDate();
            var monthIndex = gT.getMonth();
            var year = gT.getFullYear();
            var time= gT.getHours();
            var min = String(gT.getMinutes());
            if(min.length<2){
                min='0'+min;
            }*/
        
            //return day + ' ' + monthNames[monthIndex] + ' ' + year + ' ' + time + ':'+ min;
        }else{
            return '-'
        }
        
    }

    render(){
        const {visible,seciliImei,araba}=this.state;
        const thisim=this;
                

        var panes=[];
        if(araba){
            panes = [
                { menuItem: 'Hareket Bilgisi', render: () => 
                <div className='ayrinti'>
                    <List>
                    <List.Item>
                        {(araba.bilgiler) && <Label image color='red'>{araba.plaka.split(' ')[0]}<Label.Detail>{araba.plaka.split(' ')[1]+' '+araba.plaka.split(' ')[2]} </Label.Detail></Label>}
                    </List.Item>
                    <List.Item>
                        {(araba.bilgiler) && <Label image color='red'><Icon name='database'/>Zaman: {this.formatDate(araba.bilgiler.FIXDATETIME)} </Label>}
                    </List.Item>
                    <List.Item>
                        {(araba.bilgiler) && <Label image color='red'><Icon name='browser'/>Adres: {araba.bilgiler.FIXADRESS}</Label>}
                    </List.Item>
                    <List.Item>
                        {(araba.bilgiler) && <Label image color='red'><Icon name='key'/>Kont. Açma: {this.formatDate(araba.bilgiler.IGNITIONTIMEBEGIN)}</Label>}
                    </List.Item>
                    <List.Item>
                        {(araba.bilgiler) && <Label image color='red'><Icon name='shutdown'/>Kont. Kapama: {this.formatDate(araba.bilgiler.IGNITIONTIMEEND)}</Label>}
                    </List.Item>
                    <List.Item>
                        {(araba.bilgiler) && <Label image color='red'><Icon name='long arrow right'/>Son Mesafe:<Label.Detail>{Number(Number(araba.bilgiler.LASTKILOMETERS)/1000).toFixed(2).toString()} km</Label.Detail></Label>}
                    </List.Item>
                    <List.Item>
                        {(araba.bilgiler) && <Label image color='red'><Icon name='resize horizontal'/>Toplam Mesafe:<Label.Detail>{Number(Number(araba.bilgiler.DAYKILOMETERS)/1000).toFixed(2).toString()} km</Label.Detail></Label>}
                    </List.Item>
                    <List.Item>
                        {(araba.bilgiler) && <Label image color='red'><Icon name='clock'/>Genel Toplam:<Label.Detail>{Number(Number(araba.bilgiler.TOTALKILOMETERS)/1000).toFixed(2).toString()} km</Label.Detail></Label>}
                    </List.Item>
                    
                    
                    </List>
                </div> },
                { menuItem: 'Genel Bilgi', render: () => 
                <div className='ayrinti'>
                    <List>
                    <List.Item>
                        {(araba.bilgiler) && <Label image color='red'><Icon name='hashtag'/>Ar.No/Chz.No:<Label.Detail>{araba.belediyeid+'/'+araba.cihazbelediyeid}</Label.Detail></Label>}
                    </List.Item>
    
                    <List.Item>
                        {(araba.bilgiler) && <Label image color='red'><Icon name='address card'/><Label.Detail>{araba.isim}</Label.Detail></Label>}
                    </List.Item>
                    <List.Item>
                        {(araba.bilgiler) && <Label image color='red'><Icon name='phone'/><Label.Detail>{araba.gsm}</Label.Detail></Label>}
                    </List.Item>
                    <List.Item>
                        {(araba.bilgiler) && <Label image color='red'><Icon name='car'/><Label.Detail>{araba.araccinsi}</Label.Detail></Label>}
                    </List.Item>
                    <List.Item>
                        {(araba.bilgiler) && <Label image color='red'><Icon name='tag'/><Label.Detail>{araba.aracmarkasi}</Label.Detail></Label>}
                    </List.Item>
                    <List.Item>
                        {(araba.bilgiler) && <Label image color='red'><Icon name='tags'/><Label.Detail>{araba.aracmodel}</Label.Detail></Label>}
                    </List.Item>
                    <List.Item>
                        {(araba.bilgiler) && <Label image color='red'><Icon name='sitemap'/><Label.Detail>{araba.aractipi+'/'+araba.aracturu}</Label.Detail></Label>}
                    </List.Item>
                    
                    </List>
                </div> }
              ];
        }else{
            panes=[];
        }
        


        return(
        <div>
            <Sidebar.Pushable as={Segment}>
                <Sidebar className='bilgiSide'
                    as={List}
                    animation='overlay'
                    direction='right'
                    visible={visible}
                    icon='labeled'
                    inverted>
                    <div>
                    <div align="left">
                        <Button onClick={this.menuKapa} fluid icon color='red' inverted>
                            <Icon name='close' />
                        </Button>
                    </div>
                    <Tab menu={{ secondary: true, pointing: true }}  panes={panes}/>
                        
                        
                        <div className='gostergeC'>
                        {(araba) && <GostergeS araba={araba}></GostergeS>}
                        </div>
                        

                    </div>
                </Sidebar>
                <Sidebar.Pusher>
                    {renderChildren(thisim, seciliImei)}
                </Sidebar.Pusher>
        </Sidebar.Pushable>
        </div>)
    }
}

export default gostergeS;