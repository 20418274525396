import Sidebar from '../elements/SideBar';
import SidebarS from '../elements/SideBarS';
import Login from '../components/Login';
import Maps from '../elements/MapsS';

import Rapor from '../components/Rapor';


import 'moment/locale/tr';

import dbDinle from '../js/db';


import AyrintiS from '../elements/ayrintiS';

import _ from 'lodash';
import { Dimmer, Loader } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css'



var React = require('react');

function cevirMS(gelen){
    gelen=gelen.toString();
    return  Date.parse(gelen.split(' ')[0].split('.').reverse().join('-')+'T'+gelen.split(' ')[1]);
}



class App extends React.Component {
    constructor(props){
        super(props);
        this.state={
            araclar:'',
            loginPass:true,
            plakalar:{},
            list:{},
            mod:'canlı',
            genelBilgi:[],
            trafik:false,
            aracDimmer:true,
            plakaAc:true,
            animasyonlar:true,
            zoomHide:false,
            solMenuImei:null,
            simulasyonData:null,
            raporButonlarim:[],
            raporToplam:0,
            raporOpen:false,
            activeStep:'aramaVis',
            fullScreen:false,
            loaded:false,
            girisObj:null,
            token:''
        }
        
    }

    toFullScreen=()=>{
        this.setState({fullScreen:!this.state.fullScreen});
    }
    signoutClick=()=>{
        this.setState({fullScreen:false});
    }
    setFullScreen=()=>{
        if(this.state.fullScreen){
            this.launchIntoFullscreen(document.documentElement); 
        }else{
            this.exitFullscreen();
        }
    }
    launchIntoFullscreen=(element)=>{
        if(element.requestFullscreen) {
            if(!document.fullScreen){
                element.requestFullscreen();
            };
          
        } else if(element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if(element.webkitRequestFullscreen) {
          element.webkitRequestFullscreen();
        } else if(element.msRequestFullscreen) {
          element.msRequestFullscreen();
        }
    }
    exitFullscreen=()=>{
        if(document.exitFullscreen) {
            if(document.fullScreen){
                document.exitFullscreen()
            };
        } else if(document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if(document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        }
      }


    pokeFnc=()=>{
        //console.log('JUST POKED');
        /** if(this.state.loaded){
            this.setState(this.state);
        }*/
    }
    componentDidMount(){
        this.setState({loaded:true});
    }
    modDegistir=(gelen,data)=>{
        if(data){
            this.setState({mod:gelen,simulasyonData:data});
        }else{
            this.setState({mod:'canlı',raporOpen:true,activeStep:'turVis'});
        }
    }
    tcheckBoxOnChange=(active,checked)=>{
        var tempAraclar = this.state.araclar;
        _.forEach(tempAraclar,function(value, key){
            if(value[active]===true){
                    value.gosterim=checked;
            }
        });

        this.setState({araclar:tempAraclar})
    }
    checkBoxOnChange=(event,data)=>{
        var tempAraclar=this.state.araclar;
        var imei=data.name;
        tempAraclar[imei].gosterim=!tempAraclar[imei].gosterim
        this.setState({araclar:tempAraclar});
    }


    turKaydet=(gelen, toplam)=>{
        this.setState({raporButonlarim:gelen, raporToplam:toplam});
    }
    setActiveStep=(gelen)=>{
        this.setState({activeStep:gelen});
    }
    raporClose=()=>{
        this.setState({raporOpen:false});
    }
    raporHandler=()=>{
        this.setState({raporOpen:true,activeStep:'aramaVis'});
    }
    aracDiz=(gelen,girisObj)=>{
        console.log('giriş');
        console.log(gelen,girisObj);
        //this.pokeFnc();
        var plakaAc,animasyonlar,zoomHide;



        //console.log(Object.keys(gelen.tumBilgiler).length);
        if(Object.keys(gelen.tumBilgiler).length>100){
            plakaAc=false;
            animasyonlar=false;
            zoomHide=true;
        }
        dbDinle(gelen.tumBilgiler,this.bilgiGuncelle);

        this.setState({
            aracDimmer:false, 
            girisObj:girisObj,
            plakaAc:plakaAc, 
            animasyonlar:animasyonlar, 
            zoomHide:zoomHide, 
            araclar:gelen.tumBilgiler, 
            token:gelen.token, 
            loginPass:false,
            genelBilgi:gelen});
    }

    trafficHandler=(e,data)=>{
        this.setState({[data.name]:!this.state[data.name]})
    }
    imeiTransfer=(imei)=>{
        this.setState({solMenuImei:imei})
    }
    imeiSil=()=>{
        this.setState({solMenuImei:null});
    }
    visibleDegistir=(imei)=>{
        this.setState({solMenuImei:imei});
    }

    bilgiGuncelle=(gelen)=>{
        console.log('bilgi güncel', gelen)
        var basla=new Date();
        _.forEach(gelen,function(value, key){
            var ssHiz=parseFloat(value.bilgiler.SPEEDKMH);
            var ssKontak=parseFloat(value.bilgiler.IGNITION);
            var ssBattery=parseFloat(value.bilgiler.BATTERY);
            var ssFixdate=parseFloat(value.bilgiler.FIXDATETIME);
            //console.log(value.bilgiler.FIXDATETIME);
            var ssX=parseFloat(value.bilgiler.X);
            var ssY=parseFloat(value.bilgiler.Y);
            value.tumAraclar=true;

            if(ssHiz>=5&&ssKontak>0){
                value.hareketHalindekiAraclar=true;
            }else{
                value.hareketHalindekiAraclar=false;
            }
            //Duran Araçlar
            if(ssKontak===0){
                value.duranAraclar=true;
            }else{
                value.duranAraclar=false;
            }
            //Rolantideki Araçlar
            if(ssHiz<5&&ssKontak>0){
                value.rolantidekiAraclar=true;
            }else{
                value.rolantidekiAraclar=false;
            }
            //Alarm Halindeki Araçlar
            if(ssBattery===1){
                value.alarmHalindekiAraclar=true;
            }else{
                value.alarmHalindekiAraclar=false;
            }
            //Bilgi Alınamayan Araçlar
            if(ssKontak>0 && Number(basla.getTime()-Number(cevirMS(ssFixdate))>1200000)){
                value.bilgiAlinamayanAraclar=true;
            }else{
                value.bilgiAlinamayanAraclar=false;
            }
            //GPS Sinyali Alınamayan Araçlar
            if(ssX===0&&ssY===0){
                value.gpsSinyaliAlinamayanAraclar=true;
            }else{
                value.gpsSinyaliAlinamayanAraclar=false;
            }

        });

        if(this.state.raporOpen===false){
            this.setState({ araclar:gelen,aracDimmer:false});
        }
    }
    raporKapa=()=>{
        this.setState({raporOpen:false})
    }
    render(){
        const {raporOpen, plakalar, araclar}=this.state;
        this.setFullScreen();

        if(this.state.loginPass===true){
            return(
                <div className='container'>
                    <Login loginPass={this.state.loginPass} onLoginPass={this.aracDiz}></Login>
                </div>
            )
        }else{
            return(
                <div className='container'>
                    <div>
                    <Dimmer active={this.state.aracDimmer}>
                        <Loader>Araç Bilgileri Alınıyor...</Loader>
                    </Dimmer>
                    {
                        ((this.state.mod==='canlı') &&
                        <div>
                            <Rapor modDegistir={this.modDegistir} girisObj={this.state.girisObj} plakaDropdown={araclar} activeStep={'aramaVis'} raporDimmer={raporOpen} raporKapa={this.raporKapa}/>
                            <Sidebar
                                girisObj={this.state.girisObj}
                                signoutClick={this.signoutClick}
                                toFullScreen={this.toFullScreen}
                                pokeFnc={this.pokeFnc}
                                imeiTransfer={this.imeiTransfer}
                                zoomHide={this.state.zoomHide}
                                trafik={this.state.trafik}
                                plakaAc={this.state.plakaAc}
                                animasyonlar={this.state.animasyonlar}
                                trafficHandler={this.trafficHandler}
                                modDegistir={this.modDegistir}
                                aracVeri={this.state.araclar}
                                araclar={this.state.plakalar}
                                gosterim={this.state.list}
                                cboc={this.checkBoxOnChange}
                                tcboc={this.tcheckBoxOnChange}
                                turKaydet={this.turKaydet}
                                raporHandler={this.raporHandler}
                                raporOpen={this.state.raporOpen}
                                raporClose={this.raporClose}
                                activeStep={this.state.activeStep}
                                setActiveStep={this.setActiveStep}
                                raporButonlarim={this.state.raporButonlarim}
                                raporToplam={this.state.raporToplam}>
                                <AyrintiS imeiSil={this.imeiSil} veriler={this.state.araclar} solMenuImei={this.state.solMenuImei}>
                                    <Maps
                                        pokeFnc={this.pokeFnc}
                                        zoomHide={this.state.zoomHide}
                                        plakaAc={this.state.plakaAc}
                                        animasyonlar={this.state.animasyonlar}
                                        trafik={this.state.trafik}
                                        veriler={this.state.araclar}
                                        className='haritam'
                                        visibleDegistir={this.visibleDegistir}
                                        imeiSil={this.imeiSil}/>
                                </AyrintiS>
                            </Sidebar>
                        </div>
                        )
                    }
                    {
                        (this.state.mod==='simülasyon') && 
                        <div>
                            <SidebarS 
                                simulasyonData={this.state.simulasyonData} 
                                modDegistir={this.modDegistir}
                                pokeFnc={this.pokeFnc}>
                            </SidebarS>
                        </div>
                    }
                    </div>
                </div>
            )
        }

    }
}
/*

*/
export default App;