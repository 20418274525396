

import  { Icon,Form, Dropdown, Button, Label, Grid, Divider, Step } from 'semantic-ui-react';
import {Modal, notification} from 'antd';
import Workbook from 'react-excel-workbook';

import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

//var RangePicker = require('antd').DatePicker.RangePicker;
import {DatePicker, TimePicker} from 'antd';


import moment from 'moment';

//var axios = require('axios');
import axios from 'axios';

//var Tablo = require('../elements/Tablo');
import Tablo from '../elements/Tablo';
import locale from 'antd/es/date-picker/locale/tr_TR';

moment.locale('tr');
var { RangePicker } = DatePicker;
var React = require('react');



const TurButt = ({toplam, turlar, onC}) => (
    <Grid>
      {turlar.map(
          function(oge){
            return (
                
                <Grid.Row >
                    <div key={'tb'+Number(Math.random()*1000000000).toFixed().toString()}>
                <Button fluid as='div' labelPosition='right'>
                    <Button itsarray={oge} onClick={onC} fluid>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={8}>
                                Kontak Açma:
                                </Grid.Column>
                                <Grid.Column width={8}>
                                {oge[0][0].fixdatetime}
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={8}>
                                Kontak Kapatma:
                                </Grid.Column>
                                <Grid.Column width={8}>
                                {oge[0][oge[0].length-1].fixdatetime}
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Button>
                    {/*(Number(Number(oge[1])/1000).toFixed(2)>=15) && (<Label as='a' basic pointing='left'>{Number(Number(oge[1])/1000).toFixed(2).toString()} KM, {(Number((Number(oge[1])/1000).toFixed(2)*3)+60).toFixed(2)}₺</Label>)*/}
                    {/*(Number(Number(oge[1])/1000).toFixed(2)<5) && (<Label as='a' basic pointing='left'>{Number(Number(oge[1])/1000).toFixed(2).toString()} KM, 0₺</Label>)*/}
                    {/*((Number(Number(oge[1])/1000).toFixed(2)<15)&&(Number(Number(oge[1])/1000).toFixed(2)>3)) && (<Label as='a' basic pointing='left'>{Number(Number(oge[1])/1000).toFixed(2).toString()} KM, 105₺</Label>)*/}
                    
                    {(<Label as='a' basic pointing='left'>{Number(Number(oge[1])/1000).toFixed(2).toString()} KM</Label>)}
                </Button>
                
                </div>
                </Grid.Row>
                
            )
        }
      )}
      {(turlar.length>0)&&<Divider horizontal>TOPLAM: {Number(Number(toplam)/1000).toFixed(2)} km </Divider>}
    </Grid>
  );



class Rapor extends React.Component{
    constructor(props){
        super(props);
        this.state={
            raporDimmer:false,
            girisObj:props.girisObj,
            tedarikSecilen:{},
            plakaDropdown:[],
            activeStep:'aramaVis',
            dateDisabled:true,
            bsaatDisabled:true,
            bidateDisabled:true,
            bisaatDisabled:true,
            butDisabled:true,
            selectedPlaka:null,
            turAyrinti:[],
            butonlarim:[],
            itsarray:[],
            toplam:0,
            baslangicTarih:null,
            baslangicSaat:'',
            bitisTarih:null,
            bitisSaat:'',
            buGunDisabled:true,
            farkzaman:null
        }
        //this.buttonDiz=this.buttonDiz.bind(this);
        
        //this.turdiz=this.turdiz.bind(this);
    }
    componentDidMount(){
       
        var araclar=this.props.plakaDropdown;
        console.log(araclar);
        var plakaDropdown=[];

        Object.keys(araclar).map((itm,ind)=>{
            plakaDropdown.push({text:araclar[itm].plaka, value:araclar[itm].imei})
        });
        this.setState({activeStep:this.props.activeStep,butonlarim:this.props.raporButonlarim,plakaDropdown:plakaDropdown});
    }
    componentWillReceiveProps(nx){
        var araclar=nx.plakaDropdown;
        console.log(araclar);
        var plakaDropdown=[]
        Object.keys(araclar).map((itm,ind)=>{
            plakaDropdown.push({text:araclar[itm].plaka, value:araclar[itm].imei})
        });
        this.setState({raporDimmer:nx.raporDimmer, plakaDropdown:plakaDropdown})
    }
    onChange=(value, dateString)=> {
        console.log(value)
        if(value){
            if(value[0]&&value[1]){
                var baslangicTarih={
                    ayF:value[0].format('MM'),
                    gun:value[0].format('DD'),
                    yil:value[0].format('YYYY'),
                    saat:value[0].format('HH'),
                    dakika:value[0].format('mm')
                }
                var bitisTarih={
                    ayF:value[1].format('MM'),
                    gun:value[1].format('DD'),
                    yil:value[1].format('YYYY'),
                    saat:value[1].format('HH'),
                    dakika:value[1].format('mm')
                }
    
    
                this.setState({
                    baslangicTarih:baslangicTarih,bitisTarih:bitisTarih
                })
            }
        }else{
            this.setState({
                baslangicTarih:null,bitisTarih:null
            })
        }
        
    }
    basChange=(value, dateString)=>{
        var trOBJ={};
        if(value){
            trOBJ.saat=value._d.getHours();
            trOBJ.dakika=value._d.getMinutes();
            this.setState({bidateDisabled:false,baslangicSaat:trOBJ});
        }else{
            this.setState({bidateDisabled:true,bisaatDisabled:true});
        }
    }
    biDateChange=(value,dateString)=>{
        var trOBJ={};
        if(value){
            trOBJ.gun=value._d.getDate();
            trOBJ.ay=value._d.getMonth();
            trOBJ.ayF=value._d.getMonth()+1;
            trOBJ.yil=value._d.getFullYear();
            this.setState({bisaatDisabled:false,bitisTarih:trOBJ});
        }else{
            this.setState({bisaatDisabled:true});
        }
    }
    biSaatChange=(value,dateString)=>{
        var trOBJ={};
        if(value){
            trOBJ.saat=value._d.getHours();
            trOBJ.dakika=value._d.getMinutes();
            this.setState({butDisabled:false,bitisSaat:trOBJ});
        }
    }

    
    turAyir=(gelen)=>{
        var turlar=[];
        var oncekiTurBasla=0;
        if(gelen.data.length>0){
            //console.log(gelen)

            var total=0;
            var zaman=0;
            console.log(gelen.data[0].fixdatetime);
            console.log(gelen.data[gelen.data.length-1].fixdatetime)
            var ilkzaman=moment(gelen.data[0].fixdatetime,'DD.MM.YYYY hh:mm:ss');
            var sonzaman=moment(gelen.data[gelen.data.length-1].fixdatetime,'DD.MM.YYYY hh:mm:ss');
            var farkzaman= moment.duration(sonzaman.diff(ilkzaman));

            for(var i=1;i<gelen.data.length;i++){
                total+=gelen.data[i].fixlength;
            }
            console.log(total);
            turlar[0]=gelen.data;
            this.setState({toplam:total,farkzaman:farkzaman});
            this.props.buttonOnClick(null,gelen.data);
            //this.turdiz(turlar);
        }else{
            console.log('uyar');
            this.props.inactiveUpdate();
            this.props.setActiveStep('aramaVis');
            //this.setState({baslangicSaat:'',baslangicTarih:'',bitisSaat:'',bitisTarih:''});
            toast.error('⚠️Seçtiğiniz zaman aralığında sonuç bulunamadı.');
        }
    }

    butOnClick=(event, data)=>{
        this.props.butOnClick(event,data);
    }

    plakaSecildi=(selectedPlaka)=>{
        this.setState({buGunDisabled:false, dateDisabled:false, selectedPlaka:selectedPlaka});
    }
    plakaChange=(e,data)=>{
        this.plakaSecildi(data.value);
    }
    modDegistir=(e,data)=>{
        this.props.modDegistir('simülasyon',data.data)
    }
    buGunGel=()=>{
        var suan=new Date()
        var baslangicTarih={
            ayF:suan.getMonth()+1,
            gun:suan.getDate(),
            yil:suan.getFullYear(),
            saat:0,
            dakika:0
        }
        var bitisTarih={
            ayF:suan.getMonth()+1,
            gun:suan.getDate(),
            yil:suan.getFullYear(),
            saat:suan.getHours(),
            dakika:suan.getMinutes()
        }

        this.setState({
            baslangicTarih:baslangicTarih,bitisTarih:bitisTarih
        },()=>{
            this.getirStringi()
        })
    }
    secTarGel=()=>{
        this.getirStringi()
    }


    getirStringi=()=>{
        var turAyir=this.turAyir;
        //var urlm=url+this.state.selectedPlaka;
     
      

        var giden={imei:this.state.selectedPlaka,
            date:{
                bYil:this.state.baslangicTarih.yil,
                bAy:this.state.baslangicTarih.ayF,
                bGun:this.state.baslangicTarih.gun,
                bSaat:this.state.baslangicTarih.saat,
                bDakika:this.state.baslangicTarih.dakika,

                biYil:this.state.bitisTarih.yil,
                biAy:this.state.bitisTarih.ayF,
                biGun:this.state.bitisTarih.gun,
                biSaat:this.state.bitisTarih.saat,
                biDakika:this.state.bitisTarih.dakika,},
            cred:{
            username:this.props.girisObj.username,
            password:this.props.girisObj.password,
        }};
        axios.post('http://107.180.69.212:7294/usr/rapor',giden)
        .then((response)=> {
            console.log(response);
            var arrayimYazilacak=response.data
            if(arrayimYazilacak.length>0){


                var toplam=0;
                for(var i=0;i<arrayimYazilacak.length;i++){
                    toplam+=arrayimYazilacak[i].fixlength;
                    arrayimYazilacak[i].speed=Number(arrayimYazilacak[i].speed).toFixed(2);
                    if(Number(arrayimYazilacak[i].speed)>5){
                        arrayimYazilacak[i].durum='Hareket Halinde';
                    }else{
                        arrayimYazilacak[i].durum='Duruyor';
                    }
                    arrayimYazilacak[i].key=i

                }
                var ilkzaman=moment(arrayimYazilacak[0].fixdatetime,'DD.MM.YYYY hh:mm:ss');
                var sonzaman=moment(arrayimYazilacak[arrayimYazilacak.length-1].fixdatetime,'DD.MM.YYYY hh:mm:ss');
                var farkzaman= moment.duration(sonzaman.diff(ilkzaman));

                this.setState({activeStep:'listeVis',itsarray:arrayimYazilacak, toplam:toplam, farkzaman:farkzaman })

            }else{
                notification.error({
                    message: 'Kayıt Bulunamadı!',
                    description:
                      'Bu plaka için seçtiğiniz zaman aralığında bir kayıt bulunamadı.',
                    placement:'bottomRight'
                  });
            }
           // turAyir(response);
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    stepHandleClick=()=>{
        this.setState({activeStep:'aramaVis'})
    }

   
    
    render(){
        const format = 'HH:mm';
        const {buttonOnClick, inactiveUpdate} =this.props;
        const {baslangicTarih, bitisTarih ,raporDimmer, buGunDisabled, toplam, butonlarim,dateDisabled, bsaatDisabled, bidateDisabled, bisaatDisabled,plakaDropdown,activeStep, butDisabled, itsarray,farkzaman,selectedPlaka}= this.state;
        
        console.log(activeStep);
        return(
            <div>
                
            <Modal style={{ top: 0 }} footer={[]} width='80%' visible={this.props.raporDimmer} closeIcon={<Button onClick={()=>this.props.raporKapa()} color='red' circular icon='x'></Button>}>
            <Step.Group attached='top' size='large'>
                        <Step
                        active={activeStep==='aramaVis'}
                        name='aramaVis'
                        icon='search'
                        link
                        onClick={this.stepHandleClick}
                        title='Plaka Seçiniz'/>
                        
                        <Step
                        active={activeStep==='listeVis'}
                        name='listeVis'
                        icon='table'
                        title='Hareket Raporu'/>
                    </Step.Group>
                        
                        {activeStep==='aramaVis'&&
                        <div style={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center', paddingTop:'30px'}}>
                            
                                <Dropdown placeholder='Plaka seçiniz' noResultsMessage='Araç Bulunamadı.' search selection options={plakaDropdown} onChange={this.plakaChange} />
                                <div style={{paddingTop:'30px'}}>
                                    <RangePicker
                                    size="large"
                                    locale={locale}
                                    showTime={{ format: 'HH:mm' }}
                                    format="DD-MM-YYYY HH:mm"
                                    onChange={this.onChange}
                                    
                                    dropdownClassName='tarihSecC'
                                    />
                                </div>    
                                <div style={{display:'flex', flexDirection:'row', paddingTop:'30px'}}>
                                <Button disabled={(selectedPlaka)?false:true} onClick={this.buGunGel} color='green'>Bu Günü Getir</Button>
                                <Button disabled={(baslangicTarih&&bitisTarih&&selectedPlaka)?false:true} onClick={this.secTarGel} color='red'>Seçilen Tarihi Getir</Button>
                                </div>
                        </div>
                       
                        
                        
                        }

                        {activeStep==='listeVis'  && (typeof itsarray!=='undefined') &&
                        <div>
                            <Grid >
                                <Grid.Row>
                                    <Grid.Column id='budur' width={16}>
                                        <Tablo  inactiveUpdate={inactiveUpdate} data={itsarray}>
                                        {<div><button className="ui labeled green icon button"><i className="exchange icon"></i>Toplam Mesafe: {Number(toplam/1000).toFixed(2)} km </button>
                                        <button className="ui labeled green icon button"><i className="time icon"></i>Toplam Süre: {farkzaman.hours()} saat {farkzaman.minutes()} dk {farkzaman.seconds()} sn</button></div>}
                                        </Tablo>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                            <Button color='green' 
                                data={itsarray} 
                                onClick={this.modDegistir}>
                                    <Icon name='map outline'/> Haritada Göster
                            </Button>

                            <Workbook filename='Hareket Raporu.xlsx' element={<button className="ui labeled green icon button"><i className="file excel outline icon"></i>Excel'e Aktar</button>}>
                                <Workbook.Sheet data={itsarray} name="Hareket Raporu">
                                    <Workbook.Column label="Zaman" value="fixdatetime"/>
                                    <Workbook.Column label="Durum" value="durum"/>
                                    <Workbook.Column label="Hız" value="speed"/>
                                    <Workbook.Column label="Adres" value="adress"/>
                                </Workbook.Sheet>
                            </Workbook>
                        </div>
                        
                        }
            </Modal>


            </div>
        );
    }
}

export default Rapor




/**
 * <Step.Group attached='top' size='large'>
                        <Step
                        active={activeStep==='aramaVis'}
                        name='aramaVis'
                        icon='search'
                        link
                        onClick={this.stepHandleClick}
                        title='Plaka Seçiniz'/>
                        {/**
                        
                        <Step
                        active={activeStep==='turVis'}
                        name='turVis'
                        icon='calendar'
                        link
                        onClick={this.stepHandleClick}
                        title='Tur Seçiniz'
                        disabled={s2d}/>
                        
                        }
                        
                        <Step
                        active={activeStep==='listeVis'}
                        name='listeVis'
                        icon='table'
                        link
                        onClick={this.stepHandleClick}
                        title='Hareket Raporu'
                        disabled={s3d}/>
                    </Step.Group>
 */