import App from './components/App';
import { ConfigProvider } from 'antd';
import trTR from 'antd/es/locale/tr_TR';


var React= require('react');
var ReactDom =require('react-dom');
//var App = require('./components/Battle');


require('./index.css');
require('../node_modules/antd/dist/antd.css')

ReactDom.render(<ConfigProvider locale={trTR}><App/></ConfigProvider>, document.getElementById('root'));
