import React, { Component } from 'react';
import _ from 'lodash';
import {RichMarker,RichMarkerPosition} from 'js-rich-marker';
import rolantiikonk from '../img/rolantiikonk.png'

var map;
var araclar={};
var trafficLayer;
var bounds
var boundD=0;

function cevirX(xcord){
    //console.log(xcord);
    var ilkiki=Number(xcord.substring(0,2));
    var sonkisim=Number(xcord.substring(2));
    var ab=sonkisim/60;
    var snc=ilkiki+ab;
    //return xcord
    return snc;
}

function cevirY(ycord){
    var ilkuc=Number(ycord.substring(0,3));
    var sonkisim=Number(ycord.substring(3));
    var ab=sonkisim/60;
    var snc=ilkuc+ab;
    //return ycord
    return snc;
}

class Maps extends Component {
    constructor(props){
        super(props);
        this.state={
            mapsLoaded:false,
            width:768,
            height:600,
            veriler:{},
            animasyonlar:true,
            plakaAc:true,
            startZoom:false,
            zoomHide:false,
            dragState:'',
            seciliImei:null,
            mapStyle:{
                height: (window.innerHeight-48)+'px',
                width: '100%'
            },
            map:null
        }
       
    }
    componentDidMount(){
        var t=this;
        if(!window.google){
            var denetle= setInterval(denetleFnk,10);
            function denetleFnk(){
                araclar={};
                t.mapsGeldi();
                clearInterval(denetle);
            }
        }else{
            araclar={};
            t.mapsGeldi();
        }

        window.addEventListener('resize',this.resizeHandler);
        this.setState({veriler:this.props.veriler});
    }
    componentWillUnmount() {
        window.removeEventListener('resize',this.resizeHandler , true);
    }
    mapsGeldi=()=>{
        this.setState({mapsLoaded:true},this.initMap);
    }
    resizeHandler=()=>{
        //console.log('çalıştı');
        //MapStyle=({height:(window.innerHeight-48)+'px'});
        this.setState({width:window.innerWidth,height:window.innerHeight,mapStyle:{height:(window.innerHeight-48)+'px'}},this.props.pokeFnc);
    }
    ortalaFunc=()=>{
        boundD=1;
    }
    componentWillReceiveProps(newProps){
        if(map){
            if(newProps.seciliImei){
                var latLng = new window.google.maps.LatLng(cevirX(newProps.veriler[newProps.seciliImei].bilgiler.X), cevirY(newProps.veriler[newProps.seciliImei].bilgiler.Y));
                map.setCenter(latLng);
                map.setZoom(16);
            }else{
                this.ortalaFunc();
            }
            if(newProps.trafik===true){
                trafficLayer.setMap(this.state.map);
            }else{
                trafficLayer.setMap(null);
            }
            this.setState({seciliImei:newProps.seciliImei, veriler:newProps.veriler,animasyonlar:newProps.animasyonlar, plakaAc:newProps.plakaAc,zoomHide:newProps.zoomHide});
        
        }
     }
    dragBasla=()=>{
        this.setState({dragState:'başla'});
    }
    dragBitti=()=>{
        this.setState({dragState:'bitti'});
    }
    zoom_changed=()=>{
        this.setState({startZoom:true});
    }
    idle=()=>{
        this.setState({startZoom:false});
    }
    initMap=()=>{
        if(window.google){
            trafficLayer = new window.google.maps.TrafficLayer();
            map = new window.google.maps.Map(document.getElementById('map'), {
              zoom: 4,
              center: {lat: 29.363, lng: 40.044}
            });
            
            window.google.maps.event.addListener(map, 'dragstart', this.dragBasla );
            window.google.maps.event.addListener(map, 'dragend', this.dragBitti );
            if(this.props.trafik===true){
                trafficLayer.setMap(map);
            }else{
                trafficLayer.setMap(null);
            }
        }else{
            setTimeout(this.initMap,500)
        }
        
        
    }
    markerClickHandler=(imei)=>{
        this.props.visibleDegistir(imei);
    }
    render(){
        var markerClickHandler=this.markerClickHandler;
        var noktalar=this.state.veriler;
        const { plakaAc, animasyonlar, startZoom,zoomHide}=this.state;
        
        
        var tsm=this;
        console.log('buuuuu',map);
        setTimeout(()=>{

            if(map){
                bounds = new window.google.maps.LatLngBounds();
                console.log('bound var');
                if(typeof(map.getBounds()) !=='undefined' ){
                    console.log('getbound var');
                    if(Object.keys(araclar).length===0){
                        _.forEach(noktalar, function(value, key) {
                           if(typeof value.bilgiler!=='undefined'){
                                   var arrowImage = new Image();
                                   var rotationElement = document.createElement('div');
                                   var richMarkerContent = document.createElement('div');
                                   var plakaElement = document.createElement('div');
                                   var rotationStyles = 'display:block;' +
                                           '-ms-transform:      rotate(%rotationdeg);' +
                                           '-o-transform:       rotate(%rotationdeg);' +
                                           '-moz-transform:     rotate(%rotationdeg);' +
                                           '-webkit-transform:  rotate(%rotationdeg);' +
                                           'z-index: 99999;';
                                    
           
                                   var mapCenter = new window.google.maps.LatLng(cevirX(value.bilgiler.X), cevirY(value.bilgiler.Y));
                                   if(cevirX(value.bilgiler.X)>1){
                                       bounds.extend(mapCenter);
                                   }
                                   if(value.hareketHalindekiAraclar===true){
                                       arrowImage.src = './img/gidenikonk.png';
                                       plakaElement.className='ui green image label';
                                       plakaElement.innerHTML=value.plaka.split(' ')[2];
           
                                       rotationStyles = rotationStyles.split('%rotation').join(value.bilgiler.COURSE);
           
                                       if(animasyonlar){
                                           rotationElement.setAttribute('class', "marker green");
                                       }else{
                                           rotationElement.setAttribute('class', "marker");
                                       }
           
                                       arrowImage.setAttribute("class",'marginli');
                                       rotationElement.setAttribute('style', rotationStyles);
           
                                       rotationElement.appendChild(arrowImage);
                                       richMarkerContent.appendChild(rotationElement);
           
                                       if(plakaAc){
                                           richMarkerContent.appendChild(plakaElement);
                                       }
           
                                       araclar[value.imei]=new RichMarker({
                                           position: mapCenter,
                                           map: map,
                                           flat: true,
                                           anchor: RichMarkerPosition.TOP,
                                           content: richMarkerContent,
                                           marker_id: value.imei,
                                           draggable:false
                                       });
                                   }else if(value.rolantidekiAraclar===true){
                                       arrowImage.src = rolantiikonk;
                                       arrowImage.width=15;
                                       arrowImage.height=15;
           
                                       plakaElement.className='ui yellow image label';
                                       plakaElement.innerHTML=value.plaka.split(' ')[2];
           
                                       
                                       rotationStyles = rotationStyles.split('%rotation').join(value.bilgiler.COURSE);
           
                                       if(animasyonlar){
                                           rotationElement.setAttribute('class', "marker yellow");
                                       }else{
                                           rotationElement.setAttribute('class', "marker");
                                       }
           
                                       arrowImage.setAttribute("class",'marginli');
                                       rotationElement.setAttribute('style', rotationStyles);
           
                                       rotationElement.appendChild(arrowImage);
                                       richMarkerContent.appendChild(rotationElement);
           
                                       if(plakaAc){
                                           richMarkerContent.appendChild(plakaElement);
                                       }
           
                                       araclar[value.imei]=new RichMarker({
                                           position: mapCenter,
                                           map: map,
                                           flat: true,
                                           anchor: RichMarkerPosition.TOP,
                                           content: richMarkerContent,
                                           marker_id: value.imei,
                                           draggable:false
                                       });
                                   }else if(value.duranAraclar===true){
                                       arrowImage.src = './img/duranikonk.png';
           
                                       plakaElement.className='ui red image label';
                                       plakaElement.innerHTML=value.plaka.split(' ')[2];
           
                                       rotationElement.setAttribute("class", "marker");
                                       arrowImage.setAttribute("class",'marginli');
           
                                       rotationElement.appendChild(arrowImage);
                                       richMarkerContent.appendChild(rotationElement);
                                       if(plakaAc){
                                           richMarkerContent.appendChild(plakaElement);
                                       }
           
                                       araclar[value.imei]=new RichMarker({
                                           position: mapCenter,
                                           map: map,
                                           flat: true,
                                           anchor: RichMarkerPosition.TOP,
                                           content: richMarkerContent,
                                           marker_id: value.imei,
                                           draggable:false
                                       });
                                   }
           
                                   window.google.maps.event.addListener( araclar[value.imei], 'click', function(event) {
                                       //// buraya click handler
                                       markerClickHandler(this.marker_id);
                                   });
                           }else{
                               return false;
                           }
                       });
                       if(map){
                           window.google.maps.event.addListener(map, 'idle', this.idle );
                           window.google.maps.event.addListener(map, 'zoom_changed', this.zoom_changed );
                           map.fitBounds(bounds);
                           this.props.pokeFnc();
                       }
                   }
                   /////////güncellendikten sonra...
           
                   else{
                       console.log('normalde çalışan yer burası')
                       
                       var lat0=map.getBounds().getNorthEast().lat().toString();
                       var lng0=map.getBounds().getNorthEast().lng().toString();
                       var lat1=map.getBounds().getSouthWest().lat().toString();
                       var lng1=map.getBounds().getSouthWest().lng().toString();
                      
                       function testEt(X,Y){
                           var test1=false;
                           //var test1=false;
                           if(X<Number(lat0)&&X>Number(lat1)&&Y<Number(lng0)&&Y>Number(lng1)){
                               test1=true;
                           }
                           return test1
                       }
                       function zoomHideFnc(X,Y){
                           if(zoomHide){
                               if(!startZoom&&testEt(X, Y)){
                                   return true;
                               }else{
                                   return false;
                               }
                           }else{
                               return true;
                           }
                       }
                       var b2=new window.google.maps.LatLngBounds();
                       bounds=b2;
                       
                       _.forEach(noktalar, function(value, key) {
                           var mapCenter = new window.google.maps.LatLng(cevirX(value.bilgiler.X), cevirY(value.bilgiler.Y));
                            if(araclar[value.imei]){
                           if(cevirX(value.bilgiler.X)>1){
                               bounds.extend(mapCenter);
                           }
                           if(zoomHideFnc(cevirX(value.bilgiler.X), cevirY(value.bilgiler.Y))){
                               if(value.gosterim===true){
                                   var positionum = new window.google.maps.LatLng(cevirX(value.bilgiler.X), cevirY(value.bilgiler.Y));
                                   araclar[value.imei].setPosition(positionum);
                                   araclar[value.imei].setMap(map);
                                   var richMarkerContent = document.createElement('div');
                                   var arrowImage = new Image();
                                   var rotationElement = document.createElement('div');
                                   var plakaElement = document.createElement('div');
                                   var rotationStyles = 'display:block;' +
                                   '-ms-transform:      rotate(%rotationdeg);' +
                                   '-o-transform:       rotate(%rotationdeg);' +
                                   '-moz-transform:     rotate(%rotationdeg);' +
                                   '-webkit-transform:  rotate(%rotationdeg);' +
                                   'z-index: 99999;';
           
                                   if(value.hareketHalindekiAraclar===true){
                                       arrowImage.src = './img/gidenikonk.png';
           
                                       plakaElement.className='ui green image label';
                                       plakaElement.innerHTML=value.plaka.split(' ')[2];
           
           
                                       rotationStyles = rotationStyles.split('%rotation').join(value.bilgiler.COURSE);
                                       if(animasyonlar){
                                           rotationElement.setAttribute('class', "marker green");
                                       }else{
                                           rotationElement.setAttribute('class', "marker");
                                       }
                                       arrowImage.setAttribute("class",'marginli');
                                       rotationElement.setAttribute('style', rotationStyles);
                                       rotationElement.appendChild(arrowImage);
                                       richMarkerContent.appendChild(rotationElement);
                                       if(plakaAc){
                                           richMarkerContent.appendChild(plakaElement);
                                       }
                                       araclar[value.imei].setContent(richMarkerContent);
                                   }else if(value.rolantidekiAraclar===true){
                                       
                                       arrowImage.src = rolantiikonk;
                                       arrowImage.width=15;
                                       arrowImage.height=15;
           
                                       plakaElement.className='ui yellow image label';
                                       plakaElement.innerHTML=value.plaka.split(' ')[2];
           
                                       rotationStyles = rotationStyles.split('%rotation').join(value.bilgiler.COURSE);
                                       if(animasyonlar){
                                           rotationElement.setAttribute('class', "marker yellow");
                                       }else{
                                           rotationElement.setAttribute('class', "marker");
                                       }
                                       arrowImage.setAttribute("class",'marginli');
                                       rotationElement.setAttribute('style', rotationStyles);
                                       rotationElement.appendChild(arrowImage);
           
                                       richMarkerContent.appendChild(rotationElement);
           
                                       if(plakaAc){
                                           richMarkerContent.appendChild(plakaElement);
                                       }
           
                                       araclar[value.imei].setContent(richMarkerContent);
                                   }else if(value.duranAraclar===true){
                                       arrowImage.src = './img/duranikonk.png';
                                       
                                       plakaElement.className='ui red image label';
                                       plakaElement.innerHTML=value.plaka.split(' ')[2];
           
                                       rotationElement.setAttribute("class", "marker");
                                       arrowImage.setAttribute("class",'marginli');
                                       rotationElement.appendChild(arrowImage);
           
                                       richMarkerContent.appendChild(rotationElement);
           
                                       if(plakaAc){
                                           richMarkerContent.appendChild(plakaElement);
                                       }
           
                                       araclar[value.imei].setContent(richMarkerContent);
                                       //araclar[value.imei].setContent('<div class="notmarker"><img src="./img/duranikonk.png" /></div>');
                                   }
                               }else{
                                  araclar[value.imei].setMap(null);
                               }
                           }else{
                               araclar[value.imei].setMap(null);
                           }
                        }else{
                            console.log('problemli:',value)
                        }
    
                       }
                       );
                       /*
                       if(boundD===1){
                           map.fitBounds(bounds);
                           map.setZoom(map.getZoom()); 
                           boundD=0;
                       }*/
                   }
                }else{
                    console.log('getbound yok');
                }
                
    
    
            }else{
                console.log('bound yok')
            }
        },500)
        
        
        return(<div id="map" style={this.state.mapStyle}/>);
    }
}

export default Maps;