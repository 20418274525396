//import firebase from 'firebase';
import _ from 'lodash';
import openSocket from 'socket.io-client';
var io = openSocket('http://107.180.69.212:7294');
console.log(io)
//http://107.180.69.212:3000/

var _ilkVeri;
var _callBack;

io.on('guncelleme', (data) => {
  var yeniGelen=data
      _.forEach(yeniGelen, function(value, key) {
        if(_ilkVeri){
          if(_ilkVeri[value.IMEI]){
            console.log(_ilkVeri)
            _ilkVeri[value.IMEI].bilgiler=value;
            _callBack(_ilkVeri);
          }
        }
    })
});

export default function dbDinle(ilkVeri, callBack){
  _ilkVeri=ilkVeri;
  _callBack=callBack;
}
