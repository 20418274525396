import React, { Component } from 'react';
import _ from 'lodash';
import {RichMarker,RichMarkerPosition} from 'js-rich-marker';
import TimerMixin from 'react-timer-mixin';

var map;
var marker;
var path=[];
var pathLine=[];
var polyline=null;
var bounds;

function cevirX(xcord){
    var ilkiki=Number(xcord.substring(0,2));
    var sonkisim=Number(xcord.substring(2));
    var ab=sonkisim/60;
    var snc=ilkiki+ab;
    //return xcord
    return snc;
}

function cevirY(ycord){
    var ilkuc=Number(ycord.substring(0,3));
    var sonkisim=Number(ycord.substring(3));
    var ab=sonkisim/60;
    var snc=ilkuc+ab;
    //return ycord
    return snc;
}

var MapStyle;
class Maps extends Component {
    constructor(props){
        super(props);
        this.state={
            play:props.play,
            simulasyonData:props.simulasyonData,
            currFrame:0,
            width:768,
            height:600,
            markerInfo:null,
            pokeFnc:props.pokeFnc
        }
        this.initMap=this.initMap.bind(this);
        this.resizeHandler=this.resizeHandler.bind(this);
        this.oynat=this.oynat.bind(this);
    }
    componentWillMount(){ 
        bounds = new window.google.maps.LatLngBounds();
    }
    componentDidMount(){
        MapStyle={
            height: (window.innerHeight-48)+'px',
            width: '100%'
        }
        window.addEventListener('resize',this.resizeHandler);
        this.setState({simulasyonData:this.props.simulasyonData, currFrame:this.props.currFrame, play:this.props.play,pokeFnc:this.props.pokeFnc},this.initMap);
        
        
        
        //this.props.setCurrentFrame(0);
    }

    resizeHandler(){
        MapStyle=({height:(window.innerHeight-48)+'px'});
        this.setState({width:window.innerWidth,height:window.innerHeight},this.state.pokeFnc);
    }
    componentWillReceiveProps(newProps){
        this.setState({simulasyonData:newProps.simulasyonData, currFrame:newProps.currFrame, play:newProps.play,pokeFnc:newProps.pokeFnc},this.initMap);
    }
    oynat(){
        if(this.state.play){
            this.timer=TimerMixin.setTimeout(() => {
                this.props.setCurrentFrame();
            }, 1000);
        }else{

        }
    } 
    componentWillUnmount() {
        window.removeEventListener('resize',this.resizeHandler , true);
        map=null
        marker=null
        bounds = new window.google.maps.LatLngBounds();
        path=[];
        pathLine=[];
        polyline=null;
        TimerMixin.clearTimeout(this.timer);
    }
    initMap() {
        var simulasyonData=this.state.simulasyonData;
        var currFrame=this.state.currFrame;
        var value =simulasyonData[currFrame];
        var position = new window.google.maps.LatLng(cevirX(value.lat), cevirY(value.lng));
        var setCurrentFrame=this.props.setCurrentFrame;
        var richMarkerContent = document.createElement('div');
        var rotationElement = document.createElement('div');
        //var plakaElement = document.createElement('div');
        var arrowImage = new Image();

        var rotationStyles = 'display:block;' +
                '-ms-transform:      rotate(%rotationdeg);' +
                '-o-transform:       rotate(%rotationdeg);' +
                '-moz-transform:     rotate(%rotationdeg);' +
                '-webkit-transform:  rotate(%rotationdeg);' +
                'z-index: 99999;';


        bounds.extend(position);
        if(!map){
            //////////////////////////

            richMarkerContent.className='richMarkerAlignment';
            
            arrowImage.src = './img/gidenikonk.png';

            //plakaElement.className='ui green image label';
            //plakaElement.innerHTML=value.plaka.split(' ')[2];

            rotationStyles = rotationStyles.split('%rotation').join(value.course);

            rotationElement.setAttribute('class', "marker green");

            arrowImage.setAttribute("class",'marginli');
            rotationElement.setAttribute('style', rotationStyles);

            rotationElement.appendChild(arrowImage);
            richMarkerContent.appendChild(rotationElement);

            //richMarkerContent.appendChild(plakaElement);
            //////////////////////////

            map = new window.google.maps.Map(document.getElementById('mapSimulation'), {
                zoom: 4,
                center: {lat: 29.363, lng: 40.044}
            });

            marker = new RichMarker({
                position: position,
                map: map,
                flat: true,
                anchor: RichMarkerPosition.MIDDLE,
                content: richMarkerContent,
                marker_id: '0M',
                draggable:false
            });




            if(path.length<1){
                if(simulasyonData){
                    _.forEach(simulasyonData,function(val, key){
                        var nokta;
                        if(val.key===0||val.key===(simulasyonData.length-1)){
                            nokta=new window.google.maps.MarkerImage('./img/bayrak.png',
                            new window.google.maps.Size(40, 40),
                            new window.google.maps.Point(0, 0),
                            new window.google.maps.Point(12, 40));
                        }else if(val.ignition!==1){
                            nokta=new window.google.maps.MarkerImage('./img/duraknokta.png',
                            new window.google.maps.Size(10, 10),
                            new window.google.maps.Point(0, 0),
                            new window.google.maps.Point(5, 5));

                        }else if(val.durum==='Hareket Halinde'){
                            nokta=new window.google.maps.MarkerImage('./img/gidennokta.png',
                            new window.google.maps.Size(10, 10),
                            new window.google.maps.Point(0, 0),
                            new window.google.maps.Point(5, 5));
                        }else if(val.durum==='Duruyor'){
                            nokta=new window.google.maps.MarkerImage('./img/rolantinokta.png',
                            new window.google.maps.Size(10, 10),
                            new window.google.maps.Point(0, 0),
                            new window.google.maps.Point(5, 5));
                        }
                        path[key]= new window.google.maps.Marker({
                            position: new window.google.maps.LatLng(cevirX(val.lat) , cevirY(val.lng)),
                            title: val.durum+' ,'+val.adress,
                            map: map,
                            icon:nokta
                          });
                        path[key].addListener('click', function() {
                            setCurrentFrame(key)
                        });
                    });
                }
            }

            map.fitBounds(bounds);
            map.setZoom(16); 
            this.oynat();
        }else{
            
            
            pathLine=[];
            if(polyline){
                polyline.setMap(null);
                polyline=null;
            }
            
            var tempArr=this.state.simulasyonData.slice(0,this.state.currFrame+1);
            _.forEach(tempArr,function(val, key){
                pathLine[key]=new window.google.maps.LatLng(cevirX(val.lat) , cevirY(val.lng));
            });
            polyline = new window.google.maps.Polyline({
                path:pathLine,
                strokeColor:"#008000",
                strokeOpacity:1.0,
                strokeWeight:3
             });
            polyline.setMap(map);
            var positionum = new window.google.maps.LatLng(cevirX(value.lat) , cevirY(value.lng));
            marker.setPosition(positionum);
            marker.setMap(map);
            map.setCenter(positionum);
            richMarkerContent.className='richMarkerAlignment';
            
            if(value.durum==='Hareket Halinde'){
                arrowImage.src = './img/gidenikonk.png';
            }else{
                arrowImage.src = './img/rolantiikons.png';
            }

            //plakaElement.className='ui green image label';
            //plakaElement.innerHTML=value.plaka.split(' ')[2];

            rotationStyles = rotationStyles.split('%rotation').join(value.course);
            if(value.durum==='Hareket Halinde'){
                rotationElement.setAttribute('class', "marker green");
            }else{
                rotationElement.setAttribute('class', "marker yellow");
            }

            arrowImage.setAttribute("class",'marginli');
            rotationElement.setAttribute('style', rotationStyles);
            rotationElement.appendChild(arrowImage);

            richMarkerContent.appendChild(rotationElement);

            //richMarkerContent.appendChild(plakaElement);

            marker.setContent(richMarkerContent);
            
            this.oynat();
        }
    }

    render(){
        return(<div id="mapSimulation" style={MapStyle}>
        
        </div>);
    }
}

export default Maps;