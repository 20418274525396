
import {Menu,Icon,Image} from 'semantic-ui-react';

import SayrintiS from '../elements/sAyrintiS';

var React= require('react');

class Sidebarim extends React.Component {
    constructor(props){
        super(props);
        this.state={
            simulasyonData:null,
            pokeFnc:null
        }
        this.clickHandler=this.clickHandler.bind(this);
    }

    clickHandler(){
        this.props.modDegistir('canlı');
    }
    componentDidMount(){
        this.setState({simulasyonData:this.props.simulasyonData,pokeFnc:this.props.pokeFnc});
    }
    componentWillReceiveProps(newProps){
        this.setState({simulasyonData:newProps.simulasyonData,pokeFnc:newProps.pokeFnc});
    }

    render(){
        return(
            <div>
                <div>
                    <Menu color='black' inverted stackable>
                        <Menu.Item className='ustMenuOge'>
                            <Icon name='content'></Icon>
                        </Menu.Item>
                        <Menu.Item className='logoItem'>
                            <Image fluid src='./img/logo.png'/>
                        </Menu.Item>
                        
                        <Menu.Menu color='red' position='right'>
                           <Menu.Item active={true} name='simulasyonuBitir' color='red' onClick={this.clickHandler}>
                                <Icon name='close'></Icon> Simülasyonu Bitir
                           </Menu.Item>
                        </Menu.Menu>
                    </Menu>
                </div>
                <div className='icHarita'>
                    <SayrintiS pokeFnc={this.state.pokeFnc} simulasyonData={this.state.simulasyonData}></SayrintiS>
                </div>
            </div>

        )
    }
}

export default Sidebarim;